import { typedEntries } from "../../utils/objects";
import {
  EntitlementValueObject as EntitlementValueObjectDto,
  ReclaimEdition as ReclaimEditionDto,
  SubscriptionFrequency as SubscriptionFrequencyDto,
  SubscriptionOption as SubscriptionOptionDto,
  SubscriptionOptions as SubscriptionOptionsDto,
  SubscriptionPrice as SubscriptionPriceDto,
  TeamMembershipSummary as TeamMembershipSummaryDto,
} from "./client";
import {
  EntitlementIntegrations,
  EntitlementSupport,
  EntitlementTable,
  EntitlementTableRow,
  EntitlementType,
  EntitlementValueObject,
  ReclaimEditionV2,
  SubscriptionFrequencyStr,
  SubscriptionOption,
  SubscriptionOptions,
  SubscriptionPrice,
  TeamMembershipSummary,
} from "./Team.types";

export const dtoToTeamMembershipSummary = (dto: TeamMembershipSummaryDto): TeamMembershipSummary => dto;

export const dtoToSubscriptionFrequencyStr = (dto: SubscriptionFrequencyDto): SubscriptionFrequencyStr =>
  dto as SubscriptionFrequencyStr;
export const dtoToReclaimEditionV2 = (dto: ReclaimEditionDto): ReclaimEditionV2 => {
  switch (dto) {
    case ReclaimEditionDto.LITE:
    case ReclaimEditionDto.STARTER:
    case ReclaimEditionDto.BUSINESS:
    case ReclaimEditionDto.ENTERPRISE:
      return dto as ReclaimEditionV2;
    default:
      throw new Error(`Received non-V2 edition: ${dto}`);
  }
};
export const subscriptionFrequencyStrToDto = (subFrequency: SubscriptionFrequencyStr): SubscriptionFrequencyDto =>
  subFrequency as SubscriptionFrequencyDto;
export const reclaimEditionV2ToDto = (edition: ReclaimEditionV2): ReclaimEditionDto => edition as ReclaimEditionDto;

export const dtoToEntitlementValueObject = <T>(dto: EntitlementValueObjectDto): EntitlementValueObject<T> => ({
  value: dto.value as unknown as T,
  actualValue: dto.value as unknown as T,
  ...(!!dto.nextEdition && dto.nextValue !== undefined
    ? {
        isTop: false,
        nextEdition: dtoToReclaimEditionV2(dto.nextEdition),
        nextValue: dto.nextValue as unknown as T,
      }
    : {
        isTop: true,
      }),
});

export const dtoToNumberEntitlementValueObject = (dto: EntitlementValueObjectDto): EntitlementValueObject<number> => ({
  value: Number(dto.value),
  actualValue: Number(dto.actualValue),
  ...(!!dto.nextEdition && dto.nextValue !== undefined
    ? {
        isTop: false,
        nextEdition: dtoToReclaimEditionV2(dto.nextEdition),
        nextValue: Number(dto.nextValue),
      }
    : {
        isTop: true,
      }),
});

export const dtoToEntitlementTableRow = (dto: Record<string, EntitlementValueObjectDto>): EntitlementTableRow => ({
  MAX_TEAM_SIZE: dtoToNumberEntitlementValueObject(dto.MAX_TEAM_SIZE),
  SCHEDULER_WEEKS: dtoToNumberEntitlementValueObject(dto.SCHEDULER_WEEKS),
  MAX_TASKS: dtoToNumberEntitlementValueObject(dto.MAX_TASKS),
  MAX_CALENDARS: dtoToNumberEntitlementValueObject(dto.MAX_CALENDARS),
  MAX_SYNCS: dtoToNumberEntitlementValueObject(dto.MAX_SYNCS),
  MAX_HABITS: dtoToNumberEntitlementValueObject(dto.MAX_HABITS),
  CUSTOM_BLOCKING: dtoToEntitlementValueObject<boolean>(dto.CUSTOM_BLOCKING),
  MAX_SCHEDULING_LINKS: dtoToNumberEntitlementValueObject(dto.MAX_SCHEDULING_LINKS),
  MAX_1_ON_1_ORGANIZE: dtoToNumberEntitlementValueObject(dto.MAX_1_ON_1_ORGANIZE),
  MAX_1_ON_1_ATTEND: dtoToNumberEntitlementValueObject(dto.MAX_1_ON_1_ATTEND),
  INTEGRATIONS: dtoToEntitlementValueObject<EntitlementIntegrations>(dto.INTEGRATIONS),
  SUPPORT: dtoToEntitlementValueObject<EntitlementSupport>(dto.SUPPORT),
  SSO: dtoToEntitlementValueObject<boolean>(dto.SSO),
});

export const dtoToEntitlementTable = (
  dto: Record<string, Record<string, EntitlementValueObjectDto>>
): EntitlementTable =>
  (Object.entries(dto) as [ReclaimEditionV2, Record<EntitlementType, EntitlementValueObjectDto>][]).reduce(
    (table, [edition, dtoRow]) => {
      table[edition] = dtoToEntitlementTableRow(dtoRow);
      return table;
    },
    {} as EntitlementTable
  );

export const dtoToSubscriptionPrice = (dto: SubscriptionPriceDto): SubscriptionPrice => ({ ...dto });

export const dtoToSubscriptionOption = (dto: SubscriptionOptionDto): SubscriptionOption => ({
  ...dto,
  prices:
    dto.prices &&
    typedEntries(dto.prices).reduce((prices, [freq, price]) => {
      prices[freq] = dtoToSubscriptionPrice(price);
      return prices;
    }, {} as SubscriptionOption["prices"]),
});

export const dtoToSubscriptionOptions = (dto: SubscriptionOptionsDto): SubscriptionOptions => ({
  ...dto,
  options: typedEntries(dto.options).reduce((options, [edition, option]) => {
    options[edition] = dtoToSubscriptionOption(option);
    return options;
  }, {} as SubscriptionOptions["options"]),
});
