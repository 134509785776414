import { EntitlementType, ReclaimEditionStr, ReclaimEditionV2, SubscriptionFrequencyStr } from "./Team.types";

export const EDITION_V2_ORDER: readonly ReclaimEditionV2[] = Object.freeze([
  "LITE",
  "STARTER",
  "BUSINESS",
  "ENTERPRISE",
]);
export const EDITION_META: Readonly<
  Record<ReclaimEditionStr, { label: string; isTrial?: true; description?: string }>
> = Object.freeze({
  ASSISTANT: { label: "Assistant" },
  LEGACY_PRO_TRIAL: { label: "Pro", isTrial: true },
  LEGACY_TEAM_TRIAL: { label: "Team", isTrial: true },
  NONE: { label: "" },
  PRO: { label: "Pro" },
  REWARD: { label: "Team" },
  TEAM: { label: "Team" },
  TRIAL: { label: "Team", isTrial: true },
  TRIAL_BUSINESS: { label: "Business", isTrial: true },

  LITE: {
    label: "Lite",
    description: "Lite plans are great for those who are new to Reclaim and want to learn the basics for free.",
  },
  STARTER: {
    label: "Starter",
    description: "Starter plans are great for those working alone or on a small team with moderate needs.",
  },
  BUSINESS: {
    label: "Business",
    description:
      "Business plans are good for larger teams and companies who want to use Reclaim across the organization.",
  },
  ENTERPRISE: {
    label: "Enterprise",
    description: "Enterprise plans are for the largest teams and companies.  Contact us to learn more.",
  },
});

export const ENTITLEMENT_ORDER: readonly EntitlementType[] = Object.freeze([
  "MAX_TEAM_SIZE",
  "SCHEDULER_WEEKS",
  "MAX_TASKS",
  "MAX_CALENDARS",
  "MAX_SYNCS",
  "MAX_HABITS",
  "CUSTOM_BLOCKING",
  "MAX_SCHEDULING_LINKS",
  "MAX_1_ON_1_ORGANIZE",
  "MAX_1_ON_1_ATTEND",
  "INTEGRATIONS",
  "SUPPORT",
  "SSO",
]);

export const ENTITLEMENT_META: Readonly<
  {
    [ENTITLEMENT in EntitlementType]: {
      label: string;
    };
  }
> = {
  MAX_TEAM_SIZE: {
    label: "Max team size",
  },
  SCHEDULER_WEEKS: {
    label: "Scheduler range",
  },
  MAX_TASKS: {
    label: "Max tasks",
  },
  MAX_CALENDARS: {
    label: "Max calendars",
  },
  MAX_SYNCS: {
    label: "Max syncs",
  },
  MAX_HABITS: {
    label: "Max habits",
  },
  CUSTOM_BLOCKING: {
    label: "Custom blocking",
  },
  MAX_SCHEDULING_LINKS: {
    label: "Max scheduling links",
  },
  MAX_1_ON_1_ORGANIZE: {
    label: "Max 1 on 1 organize",
  },
  MAX_1_ON_1_ATTEND: {
    label: "Max 1 on 1 attend",
  },
  INTEGRATIONS: {
    label: "Integrations",
  },
  SUPPORT: {
    label: "Support",
  },
  SSO: {
    label: "SSO",
  },
};

export const SUB_FREQUENCY_ORDER: readonly SubscriptionFrequencyStr[] = Object.freeze(["MONTH", "YEAR"]);
export const SUB_FREQUENCY_META: Readonly<Record<SubscriptionFrequencyStr, { label: string }>> = Object.freeze({
  MONTH: { label: "month" },
  YEAR: { label: "year" },
});
